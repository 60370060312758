module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":768,"quality":100,"linkImagesToOriginal":true,"showCaptions":true,"markdownCaptions":true,"backgroundColor":"white","withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":100,"icon":"<span>#</span>","className":"anchor","removeAccents":true,"elements":["h1","h2","h3"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Richard H Nguyen's blog","short_name":"richardhnguyen.com","lang":"en","start_url":"/","background_color":"#0B1416","theme_color":"#0EA5E9","display":"standalone","crossOrigin":"use-credentials","icon":"static/brand-64.png","icons":[{"src":"/static/brand-16.png","sizes":"16x16","type":"image/png"},{"src":"/static/brand-32.png","sizes":"32x32","type":"image/png"},{"src":"/static/brand-64.png","sizes":"64x64","type":"image/png"},{"src":"/static/brand-96.png","sizes":"96x96","type":"image/png"},{"src":"/static/brand-128.png","sizes":"128x128","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"0b1feb78d52fceac14d674c0bf69d416"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-1WSKJFCD4F"],"gtagConfig":{"optimize_id":"GTM-TFZDTRRT","anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":false,"respectDNT":true,"exclude":["/preview/**","/do-not-track/me/too/"],"origin":"richardhnguyen.com","delayOnRouteUpdate":0}},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
