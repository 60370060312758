import * as React from "react";

const AnimatedBrand: React.FC = () => {
  return (
    <svg
      id="brand"
      className="group h-[24px]"
      height="24"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 244 40"
    >
      <title>richardhnguyen.com&apos;s brand</title>
      <g id="title-container" data-name="title-container">
        <g data-name="Layer 1">
          <g id="logo">
            <rect
              className="fill-sky-600 group-hover:fill-sky-700"
              width="40"
              height="40"
              rx="3.95"
              ry="3.95"
            />
            <polygon
              className="fill-blue-500 opacity-20 group-hover:opacity-50"
              points="38.4 36.95 32.67 14.65 34.1 5.45 10.31 3.81 8.46 6.04 6.06 29.64 13.25 36.12 38.4 36.95"
            />
            <polygon
              className="fill-blue-400 group-hover:fill-blue-500"
              points="37.25 36.19 31.52 13.89 32.95 4.68 9.16 3.05 7.3 5.28 4.91 28.88 12.1 35.35 37.25 36.19"
            />
            <path
              className="fill-sky-400 group-hover:fill-sky-500"
              d="M28.59,15.57a5.67,5.67,0,0,1-1.32,3.82,7,7,0,0,1-4.2,2.06l6.37,7.31A4.26,4.26,0,0,0,31,29.92a4.42,4.42,0,0,0,1.84.4v1.1H26.34l-6.82-8.35A4.62,4.62,0,0,0,18.33,22a2,2,0,0,0-.95-.29v5.38a8.39,8.39,0,0,0,.14,1.67,1.73,1.73,0,0,0,.55,1,2.29,2.29,0,0,0,1.19.48,14.47,14.47,0,0,0,2.1.12v1.1H10.46v-1.1a7.36,7.36,0,0,0,1.47-.12,1.63,1.63,0,0,0,.88-.47,1.85,1.85,0,0,0,.42-1,8.65,8.65,0,0,0,.11-1.58V14.5A12.61,12.61,0,0,0,13.26,13,1.88,1.88,0,0,0,12.9,12a1.33,1.33,0,0,0-.78-.46,6.91,6.91,0,0,0-1.36-.18v-1.1h9.51a11.38,11.38,0,0,1,6.15,1.4A4.39,4.39,0,0,1,28.59,15.57ZM17.38,20h1.79A6.2,6.2,0,0,0,23,19a4,4,0,0,0,1.27-3.31,3.8,3.8,0,0,0-1.07-2.83,4,4,0,0,0-3-1.07H17.88c-.33,0-.5.15-.5.46Z"
            />
            <path
              className="fill-sky-500 group-hover:fill-sky-600"
              d="M32.85,32.22H26.34a.81.81,0,0,1-.62-.29L18.9,23.58a5.33,5.33,0,0,0-.72-.75v4.22a7.48,7.48,0,0,0,.12,1.5,1,1,0,0,0,.29.56,1.52,1.52,0,0,0,.8.3,14.28,14.28,0,0,0,2,.11.8.8,0,0,1,.8.8v1.1a.8.8,0,0,1-.8.8H10.46a.8.8,0,0,2-.8-.8v-1.1a.8.8,0,0,1,.8-.8,6.46,6.46,0,0,0,1.3-.1.87.87,0,0,0,.46-.23,1.13,1.13,0,0,0,.22-.56,8,8,0,0,0,.1-1.44V14.5a11,11,0,0,0-.07-1.44,1.21,1.21,0,0,0-.19-.55.63.63,0,0,0-.35-.19,7.31,7.31,0,0,0-1.22-.15.81.81,0,0,1-.75-.8v-1.1a.8.8,0,0,1,.8-.8h9.51A12.09,12.09,0,0,1,26.85,11a5.15,5.15,0,0,1,2.54,4.57,6.48,6.48,0,0,1-1.51,4.34,6.93,6.93,0,0,1-3.34,2L30,28.23a3.55,3.55,0,0,0,1.29,1,3.75,3.75,0,0,0,1.52.33.8.8,0,0,1,.8.8v1.1A.8.8,0,0,1,32.85,32.22Zm-6.13-1.6h3.89a5.17,5.17,0,0,1-1.78-1.34L22.46,22a.83.83,0,0,1-.15-.8.81.81,0,0,1,.62-.52,6.21,6.21,0,0,0,3.73-1.79,4.85,4.85,0,0,0,1.13-3.3A3.62,3.62,0,0,0,26,12.34a9.5,9.5,0,0,0-4.6-1.24,4.47,4.47,0,0,1,2.42,1.21,4.65,4.65,0,0,1,1.3,3.4,4.73,4.73,0,0,1-1.58,3.94,6.88,6.88,0,0,1-4.35,1.17H17.38a.8.8,0,0,1-.8-.8V12.27a1.19,1.19,0,0,1,.85-1.2H13a2.11,2.11,0,0,1,.5.44,2.7,2.7,0,0,1,.52,1.33,12.3,12.3,0,0,1,.09,1.66V27.19A10.26,10.26,0,0,1,14,28.91a2.68,2.68,0,0,1-.62,1.36,2,2,0,0,1-.42.35h5a2.28,2.28,0,0,1-.44-.3,2.54,2.54,0,0,1-.8-1.44,8.38,8.38,0,0,1-.16-1.83V21.67a.8.8,0,0,1,.8-.8,2.73,2.73,0,0,1,1.34.39,5.56,5.56,0,0,1,1.42,1.3Zm-8.54-11.4h1a5.47,5.47,0,0,0,3.36-.83,3.17,3.17,0,0,0,1-2.68,3.07,3.07,0,0,0-.83-2.27,3.24,3.24,0,0,0-2.4-.83H18.18Z"
            />
            <path
              className="fill-cyan-500 group-hover:fill-cyan-600"
              d="M27.82,14.8a5.65,5.65,0,0,1-1.32,3.82,7,7,0,0,1-4.2,2.06L28.67,28a4.49,4.49,0,0,0,1.57,1.17,4.62,4.62,0,0,0,1.84.4v1.09H25.57L18.76,22.3a4.8,4.8,0,0,0-1.2-1.11,2,2,0,0,0-.95-.29v5.39A8.35,8.35,0,0,0,16.75,28a1.78,1.78,0,0,0,.55,1,2.33,2.33,0,0,0,1.2.48,14.24,14.24,0,0,0,2.1.13v1.09H9.69V29.56a7.26,7.26,0,0,0,1.47-.13A1.6,1.6,0,0,0,12,29a1.86,1.86,0,0,0,.43-1,9.65,9.65,0,0,0,.11-1.58V13.73a10.66,10.66,0,0,0-.09-1.55,1.83,1.83,0,0,0-.35-.93,1.41,1.41,0,0,0-.79-.47A8.86,8.86,0,0,0,10,10.6V9.5H19.5a11.38,11.38,0,0,1,6.15,1.4A4.41,4.41,0,0,1,27.82,14.8ZM16.61,19.25H18.4a6.18,6.18,0,0,0,3.86-1,4,4,0,0,0,1.28-3.31,3.82,3.82,0,0,0-1.08-2.83,4,4,0,0,0-3-1.07H17.11c-.33,0-.5.16-.5.47Z"
            />
            <path
              className="fill-teal-100 group-hover:fill-teal-200"
              d="M32.35,30.92H25.44l-6.89-8.45a4.65,4.65,0,0,0-1.12-1,2,2,0,0,0-.55-.22v5.09a7.8,7.8,0,0,0,.13,1.6,1.59,1.59,0,0,0,.46.86,2.26,2.26,0,0,0,1.07.42,13.87,13.87,0,0,0,2.06.12h.26v1.63H9.42V29.29h.27a6.65,6.65,0,0,0,1.41-.12,1.35,1.35,0,0,0,.74-.39A1.52,1.52,0,0,0,12.2,28a9.21,9.21,0,0,0,.11-1.54V13.73a12,12,0,0,0-.08-1.51,1.68,1.68,0,0,0-.3-.81,1.16,1.16,0,0,0-.64-.37A7.37,7.37,0,0,0,10,10.87l-.25,0V9.23H19.5a11.65,11.65,0,0,1,6.3,1.45,4.67,4.67,0,0,1,2.29,4.12,6,6,0,0,1-1.38,4,7,7,0,0,1-3.91,2.05l6.07,7a4,4,0,0,0,1.48,1.1,4.25,4.25,0,0,0,1.73.38h.27Zm-6.65-.53h6.11v-.58a4.87,4.87,0,0,1-1.68-.41,4.67,4.67,0,0,1-1.66-1.24L21.79,20.5l.46-.08a6.79,6.79,0,0,0,4.05-2,5.45,5.45,0,0,0,1.26-3.65,4.11,4.11,0,0,0-2-3.67,11.08,11.08,0,0,0-6-1.36H10.26v.58a8.59,8.59,0,0,1,1.16.17,1.64,1.64,0,0,1,.92.56,2.08,2.08,0,0,1,.42,1.06,13.47,13.47,0,0,1,.08,1.59V26.42a10.33,10.33,0,0,1-.11,1.63,2.2,2.2,0,0,1-.49,1.1,1.93,1.93,0,0,1-1,.54,6.18,6.18,0,0,1-1.26.13v.57H20.33v-.57a14.92,14.92,0,0,1-1.88-.12,2.59,2.59,0,0,1-1.32-.55A2,2,0,0,1,16.49,28a8.1,8.1,0,0,1-.14-1.71V20.64h.26a2.15,2.15,0,0,1,1.08.32A5,5,0,0,1,19,22.13ZM18.4,19.52h-2v-8a.69.69,0,0,1,.76-.74H19.5a4.28,4.28,0,0,1,3.15,1.15,4.07,4.07,0,0,1,1.15,3,4.21,4.21,0,0,1-1.38,3.52A6.47,6.47,0,0,1,18.4,19.52ZM16.88,19H18.4A5.93,5.93,0,0,0,22.09,18a3.72,3.72,0,0,0,1.18-3.1,3.58,3.58,0,0,0-1-2.64,3.78,3.78,0,0,0-2.78-1H17.11c-.21,0-.23.06-.23.2Z"
            />
          </g>
          <g
            id="title"
            className="fill-gray-800 group-hover:fill-gray-900 dark:fill-gray-50 dark:group-hover:fill-gray-100"
          >
            <path
              className="group-hover:translate-x-0"
              d="M64.12,16.23a11.92,11.92,0,0,1-.06,1.33,3.26,3.26,0,0,1-.18.82.81.81,0,0,1-.32.41,1,1,0,0,1-.49.11,1.86,1.86,0,0,1-.54-.08l-.67-.17L61,18.48a6,6,0,0,0-1-.08,4.18,4.18,0,0,0-1.25.2,5.16,5.16,0,0,0-1.3.6,8.39,8.39,0,0,0-1.4,1.08A19,19,0,0,0,54.53,22V34.57a.55.55,0,0,1-.18.4,1.45,1.45,0,0,1-.61.28,6.76,6.76,0,0,1-1.15.17q-.72.06-1.83.06c-.73,0-1.34,0-1.82-.06a6.61,6.61,0,0,1-1.15-.17,1.39,1.39,0,0,1-.61-.28.55.55,0,0,1-.18-.4V14.34a.56.56,0,0,1,.15-.39,1.13,1.13,0,0,1,.54-.29,6.21,6.21,0,0,1,1-.17c.41,0,.92-.05,1.54-.05s1.16,0,1.58.05a5.1,5.1,0,0,1,1,.17,1.08,1.08,0,0,1,.49.29.56.56,0,0,1,.15.39v2.52a18.31,18.31,0,0,1,2-1.87,11.21,11.21,0,0,1,1.77-1.17,6.1,6.1,0,0,1,1.67-.6,8.6,8.6,0,0,1,1.67-.17,8.39,8.39,0,0,1,.84,0c.3,0,.6.06.92.11a6.75,6.75,0,0,1,.84.18,1.74,1.74,0,0,1,.52.22.69.69,0,0,1,.24.24,1.8,1.8,0,0,1,.14.38,4.32,4.32,0,0,1,.09.71C64.11,15.25,64.12,15.68,64.12,16.23Z"
            />
            <path
              className="group-hover:translate-x-1 transition-transform"
              d="M76.23,7.5a2.73,2.73,0,0,1-.93,2.38,6.4,6.4,0,0,1-3.43.65,6.35,6.35,0,0,1-3.42-.63,2.64,2.64,0,0,1-.88-2.29,2.76,2.76,0,0,1,.91-2.39,6.17,6.17,0,0,1,3.45-.67,6.25,6.25,0,0,1,3.4.64A2.66,2.66,0,0,1,76.23,7.5Zm-.57,27.07a.55.55,0,0,1-.18.4,1.39,1.39,0,0,1-.61.28,6.61,6.61,0,0,1-1.15.17c-.48,0-1.09.06-1.82.06s-1.35,0-1.82-.06a6.46,6.46,0,0,1-1.15-.17,1.44,1.44,0,0,1-.62-.28.55.55,0,0,1-.18-.4V14.39a.55.55,0,0,1,.18-.4,1.56,1.56,0,0,1,.62-.29,6.09,6.09,0,0,1,1.15-.2c.47,0,1.08-.06,1.82-.06s1.34,0,1.82.06a6.24,6.24,0,0,1,1.15.2,1.5,1.5,0,0,1,.61.29.55.55,0,0,1,.18.4Z"
            />
            <path
              className="group-hover:translate-x-2 delay-[50] transition-all"
              d="M102.71,31.35c0,.39,0,.72,0,1s-.08.48-.12.67a1.82,1.82,0,0,1-.18.44,1.86,1.86,0,0,1-.47.43,7.16,7.16,0,0,1-1.22.63,13.72,13.72,0,0,1-2,.67,19.87,19.87,0,0,1-5.08.66,18.44,18.44,0,0,1-5.45-.73,10.54,10.54,0,0,1-4-2.14,8.85,8.85,0,0,1-2.43-3.46,12.35,12.35,0,0,1-.83-4.67,12.8,12.8,0,0,1,1-5.25,9.9,9.9,0,0,1,2.8-3.66A11.79,11.79,0,0,1,89,13.78a19.35,19.35,0,0,1,5.38-.71,17.58,17.58,0,0,1,4.49.59,12.4,12.4,0,0,1,1.79.62,5.18,5.18,0,0,1,1.12.59,2.66,2.66,0,0,1,.47.41,1.34,1.34,0,0,1,.21.45,4.31,4.31,0,0,1,.12.67,8.73,8.73,0,0,1,0,1,4.07,4.07,0,0,1-.3,1.85.89.89,0,0,1-.77.53,2.25,2.25,0,0,1-1.08-.32c-.38-.21-.83-.44-1.34-.7a11.21,11.21,0,0,0-1.86-.7,9.15,9.15,0,0,0-2.57-.32,5.75,5.75,0,0,0-4.48,1.71,7.27,7.27,0,0,0-1.55,5A9.65,9.65,0,0,0,89,27.31a5.42,5.42,0,0,0,1.15,2.09,4.7,4.7,0,0,0,1.91,1.26,7.79,7.79,0,0,0,2.67.42,9.17,9.17,0,0,0,2.68-.35,12.52,12.52,0,0,0,2-.79q.84-.42,1.41-.78a2,2,0,0,1,1-.35.88.88,0,0,1,.45.11.81.81,0,0,1,.29.42,4.2,4.2,0,0,1,.16.79A10,10,0,0,1,102.71,31.35Z"
            />
            <path
              className="group-hover:translate-x-3 delay-[75] transition-all"
              d="M133,34.57a.55.55,0,0,1-.18.4,1.29,1.29,0,0,1-.59.28,6.31,6.31,0,0,1-1.15.17c-.49,0-1.09.06-1.81.06s-1.35,0-1.84-.06a6.31,6.31,0,0,1-1.15-.17,1.35,1.35,0,0,1-.59-.28.55.55,0,0,1-.18-.4V23a7.43,7.43,0,0,0-.29-2.31,4.07,4.07,0,0,0-.82-1.44,3.55,3.55,0,0,0-1.39-.93,5.48,5.48,0,0,0-2-.33,6,6,0,0,0-2.9.79,14.59,14.59,0,0,0-3,2.31V34.57a.55.55,0,0,1-.18.4,1.39,1.39,0,0,1-.61.28,6.61,6.61,0,0,1-1.15.17c-.48,0-1.09.06-1.82.06s-1.35,0-1.82-.06a6.46,6.46,0,0,1-1.15-.17,1.44,1.44,0,0,1-.62-.28.55.55,0,0,1-.18-.4V4.75a.57.57,0,0,1,.18-.41,1.43,1.43,0,0,1,.62-.3,6.09,6.09,0,0,1,1.15-.2c.47,0,1.08-.06,1.82-.06s1.34,0,1.82.06a6.24,6.24,0,0,1,1.15.2,1.38,1.38,0,0,1,.61.3.57.57,0,0,1,.18.41V15.93a14.87,14.87,0,0,1,4-2.16,12.36,12.36,0,0,1,4.2-.72,13.53,13.53,0,0,1,4.58.68,7.83,7.83,0,0,1,3,1.86,7.09,7.09,0,0,1,1.66,2.76,12.82,12.82,0,0,1,.5,3.82Z"
            />
            <path
              className="group-hover:translate-x-4 delay-[100] transition-all"
              d="M162.45,34.64a.56.56,0,0,1-.3.5,2.43,2.43,0,0,1-.94.26,17.41,17.41,0,0,1-1.89.08,17.72,17.72,0,0,1-2-.08,2,2,0,0,1-.87-.26.58.58,0,0,1-.25-.5V33a12.47,12.47,0,0,1-3.75,2.08,13.82,13.82,0,0,1-4.67.75,16.9,16.9,0,0,1-3.9-.42,10,10,0,0,1-3.08-1.24,6.11,6.11,0,0,1-2-2.06,5.58,5.58,0,0,1-.71-2.85,5.23,5.23,0,0,1,.91-3.08,6.77,6.77,0,0,1,2.72-2.15,16.16,16.16,0,0,1,4.49-1.26,42.31,42.31,0,0,1,6.25-.41h2.59V21.17a5,5,0,0,0-.25-1.67,2.71,2.71,0,0,0-.84-1.19,3.65,3.65,0,0,0-1.55-.7,10.84,10.84,0,0,0-2.4-.23,16.28,16.28,0,0,0-3.37.32,25.39,25.39,0,0,0-2.61.7c-.76.26-1.39.49-1.9.71a3.38,3.38,0,0,1-1.23.31,1.07,1.07,0,0,1-.59-.16,1.15,1.15,0,0,1-.42-.45,2.64,2.64,0,0,1-.25-.72,4.75,4.75,0,0,1-.09-1,3.3,3.3,0,0,1,.15-1.11,1.71,1.71,0,0,1,.57-.74,6.09,6.09,0,0,1,1.46-.72,20.21,20.21,0,0,1,2.45-.74,30.08,30.08,0,0,1,3.06-.55,25.52,25.52,0,0,1,3.42-.22,26.54,26.54,0,0,1,5.36.47,10.25,10.25,0,0,1,3.66,1.42,5.59,5.59,0,0,1,2.09,2.49,9,9,0,0,1,.66,3.6ZM155.07,26h-2.86a19.77,19.77,0,0,0-3.11.2,7.34,7.34,0,0,0-2.09.61,2.88,2.88,0,0,0-1.18,1,2.38,2.38,0,0,0-.37,1.33,2.28,2.28,0,0,0,1.06,2,5.07,5.07,0,0,0,2.94.73,6.87,6.87,0,0,0,2.91-.62,11.17,11.17,0,0,0,2.7-1.79Z"
            />
            <path
              className="group-hover:translate-x-5 delay-[125] transition-all"
              d="M186.45,16.23a11.92,11.92,0,0,1-.06,1.33,3.26,3.26,0,0,1-.18.82.8.8,0,0,1-.31.41,1,1,0,0,1-.49.11,1.86,1.86,0,0,1-.54-.08l-.67-.17-.82-.17a6,6,0,0,0-1-.08,4.13,4.13,0,0,0-1.25.2,5,5,0,0,0-1.3.6,8.86,8.86,0,0,0-1.41,1.08A20.57,20.57,0,0,0,176.86,22V34.57a.54.54,0,0,1-.17.4,1.44,1.44,0,0,1-.62.28,6.61,6.61,0,0,1-1.15.17c-.47,0-1.08.06-1.82.06s-1.34,0-1.82-.06a6.61,6.61,0,0,1-1.15-.17,1.39,1.39,0,0,1-.61-.28.55.55,0,0,1-.18-.4V14.34a.56.56,0,0,1,.15-.39,1.13,1.13,0,0,1,.53-.29,6.41,6.41,0,0,1,1-.17c.41,0,.93-.05,1.54-.05s1.17,0,1.59.05a5.25,5.25,0,0,1,1,.17,1.08,1.08,0,0,1,.49.29.56.56,0,0,1,.15.39v2.52a17.42,17.42,0,0,1,2-1.87,11.63,11.63,0,0,1,1.76-1.17,6.29,6.29,0,0,1,1.68-.6,8.52,8.52,0,0,1,1.67-.17,8.07,8.07,0,0,1,.83,0c.3,0,.61.06.93.11a6.75,6.75,0,0,1,.84.18,1.74,1.74,0,0,1,.52.22.69.69,0,0,1,.24.24,1.85,1.85,0,0,1,.13.38,4.32,4.32,0,0,1,.09.71C186.44,15.25,186.45,15.68,186.45,16.23Z"
            />
            <path
              className="group-hover:translate-x-6 delay-[150] transition-all"
              d="M215,34.57a.64.64,0,0,1-.14.42,1.1,1.1,0,0,1-.51.28,4.23,4.23,0,0,1-1,.16c-.41,0-.91,0-1.51,0s-1.16,0-1.55,0a4.43,4.43,0,0,1-1-.16,1.25,1.25,0,0,1-.53-.28.57.57,0,0,1-.17-.42V32.33a17.44,17.44,0,0,1-4.31,2.6,13.07,13.07,0,0,1-5,.93,13.28,13.28,0,0,1-5.15-.89,9.07,9.07,0,0,1-5.36-6,14.94,14.94,0,0,1-.6-4.27,14.65,14.65,0,0,1,.76-4.85,10.07,10.07,0,0,1,2.23-3.68,9.63,9.63,0,0,1,3.64-2.33,14.17,14.17,0,0,1,5-.82,11.38,11.38,0,0,1,4.06.69,15,15,0,0,1,3.62,2V4.82a.59.59,0,0,1,.16-.41,1.28,1.28,0,0,1,.6-.3,6.24,6.24,0,0,1,1.15-.2c.48,0,1.08-.07,1.82-.07s1.35,0,1.82.07a6,6,0,0,1,1.14.2,1.39,1.39,0,0,1,.6.3.6.6,0,0,1,.17.41Zm-7.46-13.51a15.31,15.31,0,0,0-3.09-2.36,6.28,6.28,0,0,0-3.1-.82,5.38,5.38,0,0,0-2.52.56,4.54,4.54,0,0,0-1.7,1.48,6.71,6.71,0,0,0-1,2.08,8.74,8.74,0,0,0-.31,2.34,11.35,11.35,0,0,0,.27,2.48A6.48,6.48,0,0,0,197,29a4.55,4.55,0,0,0,1.63,1.51,5.27,5.27,0,0,0,2.56.56,6.3,6.3,0,0,0,1.51-.17,6.85,6.85,0,0,0,1.5-.55,10.5,10.5,0,0,0,1.56-1q.81-.63,1.77-1.53Z"
            />

            <path
              className="group-hover:translate-x-9 group-hover:scale-100 delay-[175] transition-all"
              d="M244,32.08a3.36,3.36,0,0,1-1,2.83,8.69,8.69,0,0,1-6.95,0,3.22,3.22,0,0,1-1-2.71c0-1.42.32-2.37,1-2.85a6.11,6.11,0,0,1,3.51-.73,6,6,0,0,1,3.43.71A3.25,3.25,0,0,1,244,32.08Z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default AnimatedBrand;
